import gql from 'graphql-tag';

export default gql`
mutation moveItem($obj: MoveItemInput!) {
  moveItem(object: $obj) {
    folder_id
    project_id
    page_id
  }
}
`;
