import gql from 'graphql-tag';

export default gql`
query getItemPath($folderIds: [Int!], $projectIds: [Int!], $pageIds: [Int!]) {
  item_lookup(where: {
   _or: [
      {page_id: {_in: $pageIds}},
      {project_id: {_in: $projectIds}},
      {folder_id: {_in: $folderIds}}
    ]
  }) {
    item_path
  }
}
`;
