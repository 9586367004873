import gql from 'graphql-tag';

export default gql`
query getTeamItemsOfTeamAndGroup($teamIdArray: [Int!], $groupId: Int!) {
  teams_items(where: {
    team_id: { _in: $teamIdArray },
    _or: [
      { project: {group_id: { _eq: $groupId }}},
      { folder: {group_id: { _eq: $groupId }}}
    ]
  }
) {
    project {
      project_id
      slug
      name
      lookup_entries {
        slug_path
      }
      group {
        group_id
        slug
        domain
      }
    }
    folder {
      folder_id
      slug
      name
      lookup_entries {
        slug_path
      }
      group {
        group_id
        slug
        domain
      }
    }
  }
}

`;
