var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "move" + _vm.slug,
            centered: "",
            "no-close-on-backdrop": "",
            "footer-class": "flex-nowrap"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [_c("h3", [_vm._v(_vm._s(_vm.$t("modals.move.title")))])]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function({ close }) {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "p-3 border-right-light-grey",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "p-3",
                      attrs: {
                        variant: "primary",
                        block: "",
                        disabled: _vm.moveDisabled
                      },
                      on: { click: _vm.makeTheMove }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.move")) + " ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            { staticClass: "p-5" },
            [
              _c("div", { staticClass: "text-center" }, [
                _c("p", { staticClass: "mb-2 text-uppercase" }, [
                  _vm._v(
                    _vm._s(_vm.$t("modals.move.intro", { type: _vm.pageType }))
                  )
                ]),
                _c("h3", { staticClass: "mb-5" }, [
                  _vm._v(_vm._s(_vm.itemName))
                ])
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$t("modals.move.treeselectIntro")))]),
              _c(
                "b-alert",
                {
                  staticClass: "text-center",
                  attrs: { show: _vm.isSameFolder, variant: "danger" }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("modals.move.sameFolderError")) + " "
                  )
                ]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "text-center",
                  attrs: {
                    show: _vm.isNotAllowedToMoveToTarget,
                    variant: "danger"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("modals.move.isNotAllowedToMoveToTarget")) +
                      " "
                  )
                ]
              ),
              _c("treeselect", {
                attrs: {
                  multiple: false,
                  options: _vm.allGroupsOfLoggedInUser,
                  loadOptions: _vm.loadOptions,
                  clearable: false,
                  placeholder: _vm.$t("modals.move.placeholder"),
                  noChildrenText: _vm.$t("modals.move.noChildren")
                },
                on: { select: _vm.setMoveIntoItemId }
              }),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "checkbox-1",
                        name: "checkbox-duplicate",
                        value: true,
                        "unchecked-value": false
                      },
                      model: {
                        value: _vm.isItemDuplicated,
                        callback: function($$v) {
                          _vm.isItemDuplicated = $$v
                        },
                        expression: "isItemDuplicated"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("modals.move.isItemDuplicated")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm.showAcknowledgeModal && !_vm.isItemDuplicated
        ? _c("modal-acknowledge", {
            attrs: { "action-type": "move", "page-type": _vm.pageType },
            on: { "close-acknowledge-modal": _vm.closeAcknowledgeModal }
          })
        : _vm._e(),
      _vm.showAcknowledgeModal && _vm.isItemDuplicated
        ? _c("modal-acknowledge", {
            attrs: {
              "action-type": "moveDuplicate",
              "page-type": _vm.pageType
            },
            on: { "close-acknowledge-modal": _vm.closeAcknowledgeModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }