<template>
  <div>
    <b-modal
      :id="'move'+slug"
      centered
      no-close-on-backdrop
      footer-class="flex-nowrap"
    >
      <template #modal-header>
        <h3>{{ $t('modals.move.title') }}</h3>
      </template>
      <div class="p-5">
        <div class="text-center">
          <p class="mb-2 text-uppercase">{{ $t('modals.move.intro', { type: pageType }) }}</p>
          <h3 class="mb-5">{{ itemName }}</h3>
        </div>
        <p>{{ $t('modals.move.treeselectIntro') }}</p>
        <b-alert :show="isSameFolder" variant="danger" class="text-center">
          {{ $t('modals.move.sameFolderError') }}
        </b-alert>
        <b-alert :show="isNotAllowedToMoveToTarget" variant="danger" class="text-center">
          {{ $t('modals.move.isNotAllowedToMoveToTarget') }}
        </b-alert>
        <treeselect
          :multiple="false"
          :options="allGroupsOfLoggedInUser"
          :loadOptions="loadOptions"
          :clearable="false"
          :placeholder="$t('modals.move.placeholder')"
          :noChildrenText="$t('modals.move.noChildren')"
          @select="setMoveIntoItemId"
        />
        <div class="mt-3">
          <b-form-checkbox
            id="checkbox-1"
            v-model="isItemDuplicated"
            name="checkbox-duplicate"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t('modals.move.isItemDuplicated') }}
          </b-form-checkbox>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          :disabled="moveDisabled"
          @click="makeTheMove"
        >
          {{ $t('buttons.move') }}
        </b-button>
      </template>
    </b-modal>
    <modal-acknowledge
      v-if="showAcknowledgeModal && !isItemDuplicated"
      action-type="move"
      :page-type="pageType"
      @close-acknowledge-modal="closeAcknowledgeModal"
    ></modal-acknowledge>
    <modal-acknowledge
      v-if="showAcknowledgeModal && isItemDuplicated"
      action-type="moveDuplicate"
      :page-type="pageType"
      @close-acknowledge-modal="closeAcknowledgeModal"
    ></modal-acknowledge>
  </div>
</template>

<script>
import DuplicateItem from '@/graphQlQueries/mutations/duplicateItem';
import UpdateItemOrderIndex from '@/graphQlQueries/mutations/updateItemOrderIndex';
import MoveItem from '@/graphQlQueries/mutations/moveItem';
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetPublishStateOfPageById from '@/graphQlQueries/mutations/setPublishStateOfPageById';
import SetPublishStateOfProjectById from '@/graphQlQueries/mutations/setPublishStateOfProjectById';
import SetOrderOfFolders from '@/graphQlQueries/mutations/setOrderOfFolders';
import SetOrderOfProjects from '@/graphQlQueries/mutations/setOrderOfProjects';
import SetOrderOfPages from '@/graphQlQueries/mutations/setOrderOfPages';
import GetAllChildSlugsOfFolder from '@/graphQlQueries/queries/getAllChildSlugsOfFolder';
import GetAllChildSlugsOfProject from '@/graphQlQueries/queries/getAllChildSlugsOfProject';
import GetAllDescendantsOfItem from '@/graphQlQueries/queries/getAllDescendantsOfItem';
import GetTeamItemsOfTeamAndGroup from '@/graphQlQueries/queries/getTeamItemsOfTeamAndGroup';
import GetAllFoldersAndProjectsByFolderId from '@/graphQlQueries/queries/getAllFoldersAndProjectsByFolderId';
import GetAllGroups from '@/graphQlQueries/queries/getAllGroups';
import GetAllGroupsOfCurrentUser from '@/graphQlQueries/queries/getAllGroupsOfCurrentUser';
import GetItemPath from '@/graphQlQueries/queries/getItemPath';
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import Auth from '@aws-amplify/auth';

import Treeselect from '@riophae/vue-treeselect';
import ModalAcknowledge from '@/components/modals/ModalAcknowledge.vue';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'ModalMove',
  props: [
    'slug',
    'groupDomain',
    'groupSlug',
    'slugPathOfItem',
    'pageType',
    'itemId',
    'itemName',
    'parentItem',
  ],
  components: { Treeselect, ModalAcknowledge },
  mixins: [UserDataAndAccess, SendPublishedDataToServer],
  data() {
    return {
      isItemDuplicated: false,
      allGroupsOfLoggedInUser: null,
      childObject: null,
      idGenerator: 1,
      moveTo: null,
      itemPathLookup: null,
      allDescendentItems: null,
      descendentProjectIds: [],
      descendentPageIds: [],
      selectedGroupId: null,
      nextPosOfDestinationProjectsAndFolders: null,
      nextPosOfDestinationPages: null,
      isSameFolder: false,
      isNotAllowedToMoveToTarget: false,
      showAcknowledgeModal: false,
    };
  },
  computed: {
    moveDisabled() {
      return this.isSameFolder || this.moveTo === null || this.isNotAllowedToMoveToTarget;
    },
  },
  apollo: {
    itemPathLookup: {
      variables() {
        if (this.pageType === 'folder') {
          return {
            folderIds: [this.itemId],
            projectIds: [],
            pageIds: [],
          };
        }
        if (this.pageType === 'project') {
          return {
            folderIds: [],
            projectIds: [this.itemId],
            pageIds: [],
          };
        }
        if (this.pageType === 'page') {
          return {
            folderIds: [],
            projectIds: [],
            pageIds: [this.itemId],
          };
        }
        return {
          folderIds: [],
          projectIds: [],
          pageIds: [],
        };
      },
      query: GetItemPath,
      update(data) {
        return (data.item_lookup[0]?.item_path)
          ? data.item_lookup[0].item_path
          : null;
      },
      skip() {
        return !this.groupId || !this.itemId || !this.pageType;
      },
    },
    allGroupsOfLoggedInUser: {
      variables() {
        return (!this.userObject.isSysAdmin)
          ? { uid: this.userId }
          : null;
      },
      query() {
        return (!this.userObject.isSysAdmin)
          ? GetAllGroupsOfCurrentUser
          : GetAllGroups;
      },
      update(data) {
        console.log('data', data);
        const groupObjects = [];
        if (this.userObject.isSysAdmin) {
          data.groups.forEach((groupItem) => {
            const element = {
              id: this.idGenerator,
              itemId: groupItem.group_id,
              label: groupItem.name,
              rootFolderId: groupItem.root_folder_id,
              type: 'group',
              children: null,
              group_slug: groupItem.slug,
              group_domain: groupItem.domain,
              hasRootFolderAccess: true,
            };
            this.idGenerator++;
            if (!groupObjects.find((elem) => elem.itemId === groupItem.group_id)) {
              groupObjects.push(element);
            }
          });
        } else {
          data.users_roles_groups.forEach((groupItem) => {
            if (!groupObjects.find((elem) => elem.itemId === groupItem.group.group_id)) {
              const element = {
                id: this.idGenerator,
                itemId: groupItem.group.group_id,
                label: groupItem.group.name,
                rootFolderId: groupItem.group.root_folder_id,
                type: 'group',
                children: null,
                group_slug: groupItem.slug,
                group_domain: groupItem.domain,
                hasRootFolderAccess: true,
              };
              this.idGenerator++;
              groupObjects.push(element);
            }
          });
          // check all the teams
          data.users_roles_teams.forEach((teamsObject) => {
            // check all the items of the team for the group
            teamsObject.team.teams_items.forEach((teamItems) => {
              // item can be folder or project
              let group;
              let groupId;
              let hasRootFolderAccess = false;
              if (teamItems.folder) {
                groupId = teamItems.folder.group_id;
                group = teamItems.folder.group;
                if (teamItems.folder.parent_folder_id === null) {
                  hasRootFolderAccess = true;
                }
              }
              if (teamItems.project) {
                groupId = teamItems.project.group_id;
                group = teamItems.project.group;
              }
              if (!groupObjects.find((groupObject) => groupObject.itemId === groupId)) {
                const element = {
                  id: this.idGenerator,
                  itemId: groupId,
                  label: group.name,
                  rootFolderId: group.root_folder_id,
                  type: 'group',
                  children: null,
                  group_slug: group.slug,
                  group_domain: group.domain,
                  hasRootFolderAccess,
                };
                this.idGenerator++;
                groupObjects.push(element);
              }
              const indexOfElement = groupObjects.findIndex(
                (groupObject) => groupObject.itemId === groupId,
              );
              if (indexOfElement !== -1 && hasRootFolderAccess) {
                groupObjects[indexOfElement].hasRootFolderAccess = hasRootFolderAccess;
              }
            });
          });
        }
        return groupObjects;
      },
      skip() {
        return !this.userId;
      },
      fetchPolicy: 'network-only',
    },
    allChildSlugsOfFolder: {
      variables: {
        folderId: 1222251,
      },
      query: GetAllChildSlugsOfFolder,
      update(data) {
        return data;
      },
    },
    allDescendentItems: {
      query: GetAllDescendantsOfItem,
      variables() {
        return {
          itemPath: this.itemPathLookup,
        };
      },
      update(data) {
        if (data.project_descendants.length) {
          this.descendentProjectIds = data.project_descendants.map((item) => item.project_id);
        }
        if (data.page_descendants.length) {
          this.descendentPageIds = data.page_descendants.map((item) => item.page_id);
        }
        return data;
      },
      skip() {
        return !this.itemPathLookup;
      },
    },
    allChildSlugsOfProject: {
      variables: {
        projectId: 1222251,
      },
      query: GetAllChildSlugsOfProject,
      update(data) {
        return data;
      },
    },
  },
  mounted() {
    this.$bvModal.show(`move${this.slug}`);
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === `move${this.slug}`) {
        this.$apollo.queries.allGroupsOfLoggedInUser.refresh();
      }
    });
  },
  methods: {
    getAllChildSlugsOfFolder(data) {
      const slugArray = [];
      if (data.folders) {
        data.folders.forEach((slugObject) => {
          slugArray.push(slugObject.slug);
        });
      }
      if (data.projects) {
        data.projects.forEach((slugObject) => {
          slugArray.push(slugObject.slug);
        });
      }
      if (data.pages) {
        data.pages.forEach((slugObject) => {
          slugArray.push(slugObject.slug);
        });
      }
      const numberOfFolderItems = (data.folders) ? data.folders.length : 0;
      const numberOfProjectItems = (data.projects) ? data.projects.length : 0;
      this.nextPosOfDestinationProjectsAndFolders = numberOfFolderItems + numberOfProjectItems + 1;
      this.nextPosOfDestinationPages = data.pages.length + 1;
      return slugArray;
    },
    async makeTheMove() {
      if (this.selectedGroupId === null) {
        this.selectedGroupId = this.groupId;
      }
      this.$bvModal.hide(`move${this.slug}`);
      await this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: {
          isIndicatorLoading: true,
        },
      });
      const slug = await this.checkForDuplicateSlugs();
      if (this.isItemDuplicated) {
        await this.duplicateItem(slug);
      } else {
        await this.moveItem(slug);
      }
    },
    async moveItem(slug) {
      // prepare the data object for the server
      const parent_slug_path = this.slugPathOfItem.substring(0, this.slugPathOfItem.lastIndexOf('/'));
      // here we check if we move or duplicate!!!!
      const dataToBeSendToTheServer = {
        slug: this.slug,
        parent_slug_path: parent_slug_path || '/',
        new_parent_slug_path: this.moveTo.slugPath || '/',
        group_slug: this.groupSlug,
        new_group_slug: this.moveTo.group_slug,
        group_domain: this.groupDomain,
        type: this.pageType,
      };
      const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
        action: 'moveItem',
        data: dataToBeSendToTheServer,
      });
      // create item in new destination
      // const newDataToBeSendToTheServer = {
      //   slug,
      //   parent_slug_path: this.moveTo.slugPath || '/',
      //   group_slug: this.moveTo.group_slug,
      //   type: this.pageType,
      // };
      // const newPublishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
      //   action: 'createItem',
      //   data: newDataToBeSendToTheServer,
      // });
      if (publishedDataWasSuccessfulSend /* && newPublishedDataWasSuccessfulSend */) {
        // check if moving to root folder
        const parentFolderId = (this.moveTo.type === 'group')
          ? this.moveTo.rootFolderId
          : this.moveTo.itemId;
        if (this.pageType === 'folder') {
          // move a folder (it is only possible to move a folder in a folder
          await this.$apollo.mutate({
            mutation: MoveItem,
            variables: {
              obj: {
                folder_id: this.itemId,
                destination_folder_id: parentFolderId,
                slug,
                name: this.itemName,
                order_index: this.nextPosOfDestinationProjectsAndFolders,
              },
            },
          });
          await this.$apollo.mutate({
            mutation: UpdateItemOrderIndex,
            variables: {
              obj: {
                project_id: null,
                folder_id: this.itemId,
                current_order_index: this.nextPosOfDestinationProjectsAndFolders,
                new_order_index: 1,
                parent_folder_id: parentFolderId,
              },
            },
          });
          await this.cleanupAfterMove();
        } else if (this.pageType === 'project') {
          // move a project
          if (this.moveTo.type === 'folder' || this.moveTo.type === 'group') {
            // a project can only be moved into a folder
            await this.$apollo.mutate({
              mutation: MoveItem,
              variables: {
                obj: {
                  project_id: this.itemId,
                  destination_folder_id: parentFolderId,
                  slug,
                  name: this.itemName,
                  order_index: this.nextPosOfDestinationProjectsAndFolders,
                },
              },
            });
            await this.$apollo.mutate({
              mutation: UpdateItemOrderIndex,
              variables: {
                obj: {
                  project_id: this.itemId,
                  folder_id: null,
                  current_order_index: this.nextPosOfDestinationProjectsAndFolders,
                  new_order_index: 1,
                  parent_folder_id: parentFolderId,
                },
              },
            });
            await this.cleanupAfterMove();
          }
        } else {
          // move a page
          // eslint-disable-next-line no-lonely-if
          if (this.moveTo.type === 'folder' || this.moveTo.type === 'group') {
            await this.$apollo.mutate({
              mutation: MoveItem,
              variables: {
                obj: {
                  page_id: this.itemId,
                  destination_folder_id: parentFolderId,
                  slug,
                  name: this.itemName,
                  order_index: this.nextPosOfDestinationPages,
                },
              },
            });
            await this.cleanupAfterMove();
          } else if (this.moveTo.type === 'project') {
            await this.$apollo.mutate({
              mutation: MoveItem,
              variables: {
                obj: {
                  page_id: this.itemId,
                  destination_project_id: this.moveTo.itemId,
                  slug,
                  name: this.itemName,
                  order_index: this.nextPosOfDestinationPages,
                },
              },
            });
            await this.cleanupAfterMove();
          }
        }
        const domain = this.groupDomain;
        let slug_path = this.slugPathOfItem;
        if (slug_path.length > 0) {
          slug_path = (slug_path[0] === '/')
            ? slug_path.substring(1, slug_path.length)
            : slug_path;
        }
        slug_path = slug_path.substring(0, slug_path.lastIndexOf('/'));
        await this.$apollo.mutate({
          mutation: DeleteCacheFetchMagazine,
          variables: {
            domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
            slug_path,
          },
        });
      }
    },
    async cleanupAfterMove() {
      await this.unpublishDescendentProjectAndPages();
      await this.setOrderOriginOfElements();
      await this.deactivateLoader();
      this.showAcknowledgeModal = true;
    },
    async deactivateLoader() {
      await this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: {
          isIndicatorLoading: false,
        },
      });
    },
    async checkForDuplicateSlugs() {
      let result = null;
      let allSlugs = null;
      let isDuplicateSlug = null;

      if (this.pageType === 'folder' || this.pageType === 'project') {
        const folderId = (this.moveTo.rootFolderId)
          ? this.moveTo.rootFolderId
          : this.moveTo.itemId;
        // check the slugs inside the folder
        result = await this.$apollo.queries.allChildSlugsOfFolder.refetch({
          folderId,
        });
        allSlugs = this.getAllChildSlugsOfFolder(result.data);
      }
      if (this.pageType === 'page') {
        if (this.moveTo.type === 'folder' || this.moveTo.type === 'group') {
          const folderId = (this.moveTo.rootFolderId)
            ? this.moveTo.rootFolderId
            : this.moveTo.itemId;
          // check the slugs inside the folder
          result = await this.$apollo.queries.allChildSlugsOfFolder.refetch({
            folderId,
          });
          allSlugs = this.getAllChildSlugsOfFolder(result.data);
        }
        if (this.moveTo.type === 'project') {
          // move page to project
          // check the slugs inside the folder
          result = await this.$apollo.queries.allChildSlugsOfProject.refetch({
            projectId: this.moveTo.itemId,
          });
          allSlugs = this.getAllChildSlugsOfFolder(result.data);
        }
      }
      // check if the item slug is in the array
      if (allSlugs) {
        isDuplicateSlug = allSlugs.includes(this.slug);
      }
      if (isDuplicateSlug) {
        // slug exists so change the slug in the table!
        // do a mutation to change the slug!
        let newSlug;
        // find the right postfix
        for (let i = 1; i < 100; i++) {
          newSlug = `${this.slug}-${i}`;
          if (allSlugs && !allSlugs.includes(newSlug)) {
            break;
          }
        }
        return newSlug;
      }
      return this.slug;
    },
    closeAcknowledgeModal() {
      this.showAcknowledgeModal = false;
      this.$emit('item-moved');
    },
    setMoveIntoItemId(item) {
      this.isNotAllowedToMoveToTarget = false;
      this.isSameFolder = item.type === this.parentItem.type && item.itemId === this.parentItem.id;
      // use the following condition if the item will be moved to a root folder!
      // a team user is not allowed to move the item to the group level!
      if (!item.hasRootFolderAccess && item.type === 'group') {
        this.isNotAllowedToMoveToTarget = true;
      }
      if (
        item.type === 'group'
        && this.parentItem.type === 'folder'
        && this.parentItem.id === item.rootFolderId
      ) {
        this.isSameFolder = true;
      }
      this.moveTo = item;
    },
    async setOrderOriginOfElements() {
      let result = null;
      if (this.pageType === 'page') {
        if (this.parentItem.type === 'project') {
          result = await this.$apollo.queries.allChildSlugsOfProject.refetch({
            projectId: this.parentItem.id,
          });
        } else {
          result = await this.$apollo.queries.allChildSlugsOfFolder.refetch({
            folderId: this.parentItem.id,
          });
        }
        if (result.data.pages.length > 0) {
          await Promise.all(result.data.pages.map(async (page, index) => {
            await this.$apollo.mutate({
              mutation: SetOrderOfPages,
              variables: {
                pageId: page.page_id,
                orderIndex: index + 1,
              },
            });
          }));
        }
      } else {
        result = await this.$apollo.queries.allChildSlugsOfFolder.refetch({
          folderId: this.parentItem.id,
        });
        const allItems = [];
        result.data.folders.forEach((folder) => {
          allItems.push({
            ...folder,
            type: 'folder',
          });
        });
        result.data.projects.forEach((project) => {
          allItems.push({
            ...project,
            type: 'project',
          });
        });
        await Promise.all(allItems.map(async (item, index) => {
          if (item.type === 'project') {
            await this.$apollo.mutate({
              mutation: SetOrderOfProjects,
              variables: {
                projectId: item.project_id,
                orderIndex: index + 1,
              },
            });
          }
          if (item.type === 'folder') {
            await this.$apollo.mutate({
              mutation: SetOrderOfFolders,
              variables: {
                folderId: item.folder_id,
                orderIndex: index + 1,
              },
            });
          }
        }));
      }
      return 'updated order of items';
    },
    async unpublishDescendentProjectAndPages() {
      // unpublish all the moved page and project items
      await Promise.all(this.descendentProjectIds.map(async (projectId) => {
        await this.$apollo.mutate({
          mutation: SetPublishStateOfProjectById,
          variables: {
            projectId,
            published: false,
          },
        });
      }));
      await Promise.all(this.descendentPageIds.map(async (pageId) => {
        await this.$apollo.mutate({
          mutation: SetPublishStateOfPageById,
          variables: {
            id: pageId,
            published: false,
          },
        });
      }));
      return 'updated publish status of project and page';
    },
    async loadOptions({ action, parentNode }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        // run the query and retrieve all folder and projects of the parent
        const folderId = (parentNode.type === 'group') ? parentNode.rootFolderId : parentNode.itemId;
        // use the group id only for the team items one level below the group item
        let groupId;
        if (parentNode.type === 'group') {
          this.selectedGroupId = parentNode.itemId;
          groupId = parentNode.itemId;
        }
        let childFolderData;
        // show team view items
        if (parentNode.type === 'group' && parentNode.hasRootFolderAccess === false) {
          const result = await this.$apollo.query({
            query: GetTeamItemsOfTeamAndGroup,
            variables: {
              teamIdArray: this.userObject.teamIdsOfUser,
              groupId,
            },
            fetchPolicy: 'network-only',
          });
          const folderArray = [];
          const projectArray = [];
          result.data.teams_items.forEach((teamItem) => {
            if (teamItem.folder) {
              folderArray.push(teamItem.folder);
            }
            if (teamItem.project) {
              projectArray.push(teamItem.project);
            }
          });
          childFolderData = {
            data: {
              folders: folderArray,
              projects: projectArray,
            },
          };
        } else {
          // because we don't want to move in the same folder
          childFolderData = await this.$apollo.query({
            query: GetAllFoldersAndProjectsByFolderId,
            variables: {
              folderId,
            },
            fetchPolicy: 'network-only',
          });
        }
        // eslint-disable-next-line no-param-reassign
        parentNode.children = this.prepareTheDataForChildrenOutput(childFolderData.data);
      }
    },
    prepareTheDataForChildrenOutput(data) {
      const childrenArray = [];
      // we have data.folders
      if (data.folders.length > 0) {
        data.folders.forEach((folderItem) => {
          if (this.pageType !== 'folder' || this.itemId !== folderItem.folder_id) {
            childrenArray.push({
              id: this.idGenerator,
              itemId: folderItem.folder_id,
              label: folderItem.name,
              type: 'folder',
              children: null,
              slugPath: folderItem.lookup_entries[0].slug_path,
              group_slug: folderItem.group.slug,
              group_domain: folderItem.group.domain,
            });
            this.idGenerator++;
          }
        });
      }
      // we have data.links
      // use the project items only if the item type is 'page'
      if (this.pageType === 'page' && data.projects.length > 0) {
        data.projects.forEach((projectItem) => {
          childrenArray.push({
            id: this.idGenerator,
            itemId: projectItem.project_id,
            label: projectItem.name,
            type: 'project',
            slugPath: projectItem.lookup_entries[0].slug_path,
            group_slug: projectItem.group.slug,
            group_domain: projectItem.group.domain,
          });
          this.idGenerator++;
        });
      }
      if (childrenArray.length === 0) {
        return null;
      }
      return childrenArray;
    },
    async duplicateItem(newSlug) {
      const parent_slug_path = this.slugPathOfItem.substring(0, this.slugPathOfItem.lastIndexOf('/'));
      const dataToBeSendToTheServer = {
        slug: this.slug,
        new_slug: newSlug,
        parent_slug_path: parent_slug_path || '/',
        new_parent_slug_path: this.moveTo.slugPath || '/',
        group_slug: this.groupSlug,
        new_group_slug: this.moveTo.group_slug,
        group_domain: this.moveTo.group_domain,
        type: this.pageType,
        actionType: 'moveAndDuplicate',
      };
      const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
        action: 'moveItem',
        data: dataToBeSendToTheServer,
      });

      if (publishedDataWasSuccessfulSend) {
        // run the action and create all duplicates
        let destination_folder_id = null;
        if (this.moveTo.type === 'group') {
          destination_folder_id = this.moveTo.rootFolderId;
        }
        if (this.moveTo.type === 'folder') {
          destination_folder_id = this.moveTo.itemId;
        }
        const duplicateObject = {
          name: this.itemName,
          slug: newSlug,
          destination_folder_id,
          destination_project_id: (this.moveTo.type === 'project') ? this.moveTo.itemId : null,
          page_id: (this.pageType === 'page') ? this.itemId : null,
          folder_id: (this.pageType === 'folder') ? this.itemId : null,
          project_id: (this.pageType === 'project') ? this.itemId : null,
        };
        const { data: { duplicateItem } } = await this.$apollo.mutate({
          mutation: DuplicateItem,
          variables: {
            object: duplicateObject,
          },
        });
        if (duplicateItem[this.pageType]) {
          await this.$apollo.queries.allDescendentItems.refetch({
            itemPath: duplicateItem[this.pageType].item_path,
          });
        }
        await this.unpublishDescendentProjectAndPages();
        await this.setTheOrderOfTheDuplicatedItem(duplicateItem);
        await this.deactivateLoader();
        this.showAcknowledgeModal = true;
        // create a new gist entry for project create
        try {
          const authenticatedUser = await Auth.currentAuthenticatedUser();
          const { email } = authenticatedUser.attributes;
          if (window.gist && email) {
            switch (this.pageType) {
              case 'folder':
                window.gist.identify(email, { email, tags: 'folders_trigger' });
                break;
              case 'project':
                window.gist.identify(email, { email, tags: 'projects_trigger' });
                break;
              case 'page':
                window.gist.identify(email, { email, tags: 'pages_trigger' });
                break;
              default:
                console.error('the page type doesn\'t exist', this.pageType);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async setTheOrderOfTheDuplicatedItem(duplicateItem) {
      switch (this.pageType) {
        case 'folder':
          await this.$apollo.mutate({
            mutation: UpdateItemOrderIndex,
            variables: {
              obj: {
                project_id: null,
                folder_id: duplicateItem.folder.folder_id,
                current_order_index: duplicateItem.folder.order_index,
                new_order_index: 1,
                parent_folder_id: duplicateItem.folder.parent_folder_id,
              },
            },
          });
          break;
        case 'project':
          await this.$apollo.mutate({
            mutation: UpdateItemOrderIndex,
            variables: {
              obj: {
                project_id: duplicateItem.project.project_id,
                folder_id: null,
                current_order_index: duplicateItem.project.order_index,
                new_order_index: 1,
                parent_folder_id: duplicateItem.project.parent_folder_id,
              },
            },
          });
          break;
        case 'page':
          await this.$apollo.mutate({
            mutation: SetOrderOfPages,
            variables: {
              pageId: duplicateItem.page.page_id,
              orderIndex: this.nextPosOfDestinationPages,
            },
          });
          break;
        default:
          console.log('no item to update');
      }
      return 'order of duplicate item set';
    },
    getAllChildSlugsOfParentItem(data) {
      const slugArray = [];
      let folderLength = 0;
      let projectLength = 0;
      let pageLength = 0;
      if (data.folders) {
        data.folders.forEach((slugObject) => {
          slugArray.push(slugObject.slug);
        });
        folderLength = data.folders.length;
      }
      if (data.projects) {
        data.projects.forEach((slugObject) => {
          slugArray.push(slugObject.slug);
        });
        projectLength = data.projects.length;
      }
      if (data.pages) {
        data.pages.forEach((slugObject) => {
          slugArray.push(slugObject.slug);
        });
        pageLength = data.pages.length;
      }
      this.nextPosOfDestinationProjectsAndFolders = folderLength + projectLength + 1;
      this.nextPosOfDestinationPages = pageLength + 1;
      return slugArray;
    },
  },
};
</script>

<style scoped lang="scss">
.show-url-icon svg {
  font-size: 80px;
}

/deep/ .disabled {
  cursor: not-allowed;
}
</style>
